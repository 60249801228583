import '../styles/globals.css';
import type { AppProps } from 'next/app';
import '@livekit/components-styles';
import '@livekit/components-styles/prefabs';
import { DefaultSeo } from 'next-seo';
import { ToastContainer } from 'react-toastify';

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <DefaultSeo
        title="Focus | Fireflies.ai"
        titleTemplate="%s"
        defaultTitle="Focus | Fireflies.ai"
        description="Focus meetings by Fireflies."
        twitter={{
          handle: '@livekitted',
          site: '@livekitted',
          cardType: 'summary_large_image',
        }}
        additionalMetaTags={[
          {
            property: 'theme-color',
            content: '#070707',
          },
        ]}
        additionalLinkTags={[
          {
            rel: 'icon',
            href: '/favicon.ico',
          },
          {
            rel: 'apple-touch-icon',
            href: '/images/apple-touch.png',
            sizes: '180x180',
          },
        ]}
      />
      <Component {...pageProps} />
      <ToastContainer
				position="bottom-center"
				autoClose={3000}
				hideProgressBar
				newestOnTop
				closeOnClick
				rtl={false}
        style={{ width: '400px' }}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="dark"
			/>
    </>
  );
}

export default MyApp;
